import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

const People = () => {
    const [people, setPeople] = useState([]);

    useEffect(() => {
        const getPeople = async () => {
            const resp = await fetch('/api/people');
            const peopleResp = await resp.json();
            setPeople(peopleResp);
        };

        getPeople();
    }, []);

    return (
        <div>
            <h1>People</h1>
            {people.map(person => (
                <div key={person.id}>
                    <h2>
                        <Link to={`/people/${person.id}`}>{person.name}</Link>
                    </h2>
                </div>
            ))}
        </div>
    );
};

export default People;