import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const Person = () => {
    const [person, setPerson] = useState({});
    const { id } = useParams();

    useEffect(() => {
        const getPerson = async () => {
            const resp = await fetch(`/api/person/${id}`);
            const personResp = await resp.json();
            setPerson(personResp);
        };

        getPerson();
    }, [id]);

    if (!Object.keys(person).length) return <div />;

    return (
        <div>
            <h1>{person.name}</h1>
            <p>
                <Link to="/">Go back</Link>
            </p>
        </div>
    );
};

export default Person;