import { Routes, Route } from 'react-router-dom';

import People from './components/people';
import Person from './components/person';

function App() {
  return (
    <Routes>
      <Route path="/" element={<People />} />
      <Route path="/people/:id" element={<Person />} />
    </Routes>
  );
}

export default App;